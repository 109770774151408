var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "HistoryPopover" },
    [
      _c(
        "a-popover",
        {
          attrs: {
            title: "近期访问",
            trigger: "click",
            placement: "bottomRight",
            "get-popup-container": function (triggerNode) {
              return triggerNode.parentNode
            },
            "overlay-class-name": "history-popover",
          },
          model: {
            value: _vm.historyVisible,
            callback: function ($$v) {
              _vm.historyVisible = $$v
            },
            expression: "historyVisible",
          },
        },
        [
          _c("template", { slot: "content" }, [
            _c("div", { staticClass: "popover-content" }, [
              _c("div", { staticClass: "popover-history" }, [
                _c(
                  "div",
                  { staticClass: "history-content" },
                  [
                    _vm.historyList && _vm.historyList.length > 0
                      ? _vm._l(_vm.historyList, function (history, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "history flex-align-center",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpRoute(
                                    history.routePath,
                                    history
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "first-route" }, [
                                _vm._v(_vm._s(history.firstRouteName)),
                              ]),
                              _c("span", { staticClass: "second-route" }, [
                                _vm._v(_vm._s(history.secondRouteName)),
                              ]),
                            ]
                          )
                        })
                      : [_c("NoData")],
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _vm._t("icon"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }